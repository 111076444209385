import React from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import {isValidPhoneNumber, CountryCode} from "libphonenumber-js";
import {Flex} from '@chakra-ui/core'
import {useTranslation} from "~/i18n/hooks";
import { formSchema } from "./formSchema";
import FormController from "./FormController";
import {CreateTenantValues} from './formSchema'
import CustomCheckoxInput from "~/ui/inputs/CustomCheckoxInput";
import { CheckboxInterface } from "~/pages";

interface Props {
  defaultValues?: Partial<FormData>;
  onSubmit: (values: Partial<CreateTenantValues>) => void;
  checkbox: CheckboxInterface;
  setCheckbox: React.Dispatch<React.SetStateAction<CheckboxInterface>>;
  children: (options: {
    form: JSX.Element;
    isDirty: boolean;
    submit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  }) => JSX.Element;
}

const CreateForm: React.FC<Props> = ({ checkbox, setCheckbox, children, onSubmit}) => {
  const t = useTranslation();
  const {
    handleSubmit: submit,
    errors,
    control,
    formState,
  } = useForm<Partial<CreateTenantValues>>({
    defaultValues: formSchema.defaultValues,
    resolver: yupResolver(formSchema.validationSchema(t)),
  });

  const [selectedCountryISO, setSelectedCountryISO] = React.useState<CountryCode>("AR");
  const [personalPhoneSelectedCountryISO, setPersonalPhoneSelectedCountryISO] = 
    React.useState<CountryCode>("AR")
  const [isValid, setIsValid] = React.useState<any>({
    phone: true,
    personalPhone: true
  });

  function handleSubmit(formData: CreateTenantValues) {

    const isValidNumber = isValidPhoneNumber(formData.phone, selectedCountryISO);
    const personalPhoneIsValid = isValidPhoneNumber(
      formData.personalPhone, 
      personalPhoneSelectedCountryISO
    )
    if (isValidNumber && personalPhoneIsValid) {
      const tenantTocreate = {
        ...formData,
        termsAndConditions: checkbox.termsAndConditions,
        pencyNewsletter: checkbox.pencyNewsletter,
        countryISO: {
          selectedCountryISO,
          personalPhoneSelectedCountryISO
        }
      }
      return onSubmit(tenantTocreate);
    } else {
      setIsValid({
        phone: isValidNumber,
        personalPhone: personalPhoneIsValid
      });
    }
  }

  return children({
    submit: submit(handleSubmit),
    isDirty: formState.isDirty,
    form: (
      <form onSubmit={submit(handleSubmit)}>
        {formSchema.builder.map((input) => {
          if (input.name !== 'recaptcha'){
            return <FormController 
            key={input.id} 
            errors={errors} 
            control={control} 
            isValid={isValid}
            setIsValid={setIsValid}
            setSelectedCountryISO={setSelectedCountryISO}
            setPersonalPhoneSelectedCountryISO={setPersonalPhoneSelectedCountryISO}
            input={input} 
          />
        }})}
        <Flex flexDirection='column' marginBottom='1rem'>
          <CustomCheckoxInput 
            defaultIsChecked={true} 
            name="termsAndConditions" 
            checkbox={checkbox}
            handleChange={(e) => setCheckbox({
              ...checkbox,
              termsAndConditions: e.target.checked
            })}
            input={{
              label: "form.termsAndConditions.label",
              terms: "form.termsAndConditions.terms",
              privacy: "form.termsAndConditions.privacy",
            }}
            /> 
          <CustomCheckoxInput 
            name='pencyNewsletter'
            defaultIsChecked={false}  
            checkbox={checkbox}
            handleChange={(e) => setCheckbox({
              ...checkbox,
              pencyNewsletter: e.target.checked
            })}
            input={{
              label: "form.pencyNewsletter.label",
            }}
            />
            </Flex>
            <FormController 
            key={formSchema.builder[8].id} 
            errors={errors} 
            control={control} 
            isValid={isValid}
            input={formSchema.builder[8]} />
      </form>
    ),
  });
};

export default CreateForm;