export const cardsContent = [
    {
        image: "./card4.png",
        title: "planCard.initial.title",
        subtitle: "planCard.initial.subtitle",
        footerText: "planCard.initial.footerText"
    },
    {
        image: "./card2.png",
        title: "planCard.commercial.title",
        subtitle: "planCard.commercial.subtitle",
        since: "common.since",
        footerText: "planCard.commercial.footerText"
    },
    {
        image: "./card3.png",
        title: "planCard.premium.title",
        subtitle: "planCard.premium.subtitle",
        since: "common.since",
        footerText: "planCard.premium.footerText",
    }
]