import React from "react";

import ProductContext from "./context";

export function useAnalytics() {
  const {
    actions: {track},
  } = React.useContext(ProductContext);

  return track;
}
