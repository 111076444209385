import React, {useEffect} from "react";
import {Stack, Text, Link, Flex, Box, Image} from "@chakra-ui/core";
import CheckIcon from "@pency/components/icons/Check";
import Button from "@pency/components/controls/Button";
import {GetStaticProps} from "next";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import Head from "next/head";
import {i18n} from "next-i18next";
import { Trans } from 'react-i18next'

import reporter from "~/reporting";
import CreateForm from "~/forms/CreateForm";
import api from "~/tenant/api/client";
import {useAnalytics} from "~/analytics/hooks";
import {getLocale} from "~/i18n/selectors";
import {useTranslation} from "~/i18n/hooks";
import {useAppboy} from "~/utils/hooks/useAppboy";
import { cardsContent } from "~/components/PlanCard/CardContent";
import PlanCard from "~/components/PlanCard/PlanCard";
import { Global, css} from "@emotion/core";
import { useRouter } from 'next/router'
import {CreateTenantValuesInterface} from '@pency/api/tenant/controllers/post'
import Heading from "~/components/ui/Heading";
import PencyLogo from "~/common/svgs/PencyLogo";

export interface CheckboxInterface {
  termsAndConditions: boolean,
  pencyNewsletter: boolean
}

const SignupRoute: React.FC = () => {
  const analytics = useAnalytics();
  const [status, setStatus] = React.useState<"init" | "pending" | "resolved" | "rejected">("init");
  const [tenant, setTenant] = React.useState(null);
  const [error, setError] = React.useState(null);
  const router = useRouter()
  const locale = getLocale();
  const t = useTranslation();
  const {appboy, appboyKey} = useAppboy();

  useEffect(() => {
    if (appboy !== undefined) {
      appboy.initialize(appboyKey, {
        baseUrl: "sdk.iad-05.braze.com",
      });
    }
  }, [appboy, appboyKey]);

  function setUserSession(id: string, email: string, phone: string, slug: string) {
    appboy.changeUser(`${id}`);
    appboy.openSession();
    appboy.getUser().setEmail(`${email}`);
    appboy.getUser().setPhoneNumber(`${phone}`);
    appboy.getUser().addToCustomAttributeArray("slug", `${slug}`);
    appboy.logCustomEvent("CREATE-ACCOUNT");
  }

  function handleSubmit(formData: CreateTenantValuesInterface) {
    setStatus("pending");
    const {slug, email} = formData;
    return api
      .create(formData, i18n.language)
      .then((tenant) => {
        // Save tenant for success screen
        setTenant(tenant);
        // Track account creation log
        analytics.createAccount(slug, tenant.id);

        //Braze account created
        setUserSession(tenant.id, email, tenant.phone, tenant.slug);

        router.push(`/admin/${tenant.slug}`)
        // Show success screen
        //return setStatus("resolved");
      })
      .catch((error) => {
        setError(error?.message || t("form.errorMessages.long"));
        // Report to sentry
        reporter.message(error?.message || t("form.errorMessages.creation"), {
          extras: {email, slug},
          origin: "creationForm",
        });

        return setStatus("rejected");
      });
  }

  const [checkbox, setCheckbox] = React.useState<CheckboxInterface>({
    termsAndConditions: true,
    pencyNewsletter: false
  })

  return (
    <Box backgroundColor="#48BB78" paddingBottom={{base: '1rem', md: '5.5rem'}}>
    <Flex
      flexDirection={{base: "column", lg: "row"}}
      height="100%"
      backgroundColor="#48BB78"
      minHeight={{base: "auto", lg: "100vh"}}
    >
      <Head>
        <Global styles={css``} />
        <link href={`${process.env.URL}/${locale}`} rel="canonical" />
        <link href={process.env.URL} hrefLang="x-default" rel="alternate" />
        <link href={process.env.URL} hrefLang="es" rel="alternate" />
        <link href={`${process.env.URL}/pt`} hrefLang="pt" rel="alternate" />
        <link href={`${process.env.URL}/en`} hrefLang="en" rel="alternate" />
        <title>{t("meta.title")}</title>
        <meta content={t("meta.description")} name="description" />
        <meta content={t("meta.keywords")} name="keywords" />
        <meta content={t("meta.title")} name="twitter:title" />
        <meta content={t("meta.description")} name="twitter:description" />
        <meta
          content={`${process.env.URL}/og-image-${i18n.language}.jpg`}
          property="twitter:image"
        />
        <meta content={`${process.env.URL}/${locale}`} property="og:url" />
        <meta content={t("meta.title")} property="og:title" />
        <meta content={t("meta.description")} property="og:description" />
        <meta content={t("meta.title")} property="og:image:alt" />
        <meta content={`${process.env.URL}/og-image-${i18n.language}.jpg`} property="og:image" />
        <meta
          content={`${process.env.URL}/og-image-${i18n.language}.jpg`}
          property="og:image:url"
        />
        <meta content="i3s5ctil62czt0b7w98rd307q8t940" name="facebook-domain-verification" />

        <>
          {/* Google Tag Manager */}
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-PFC2ZSN');`,
            }}
          />
          {/* End Google Tag Manager */}
          {/* Google Tag Manager (noscript) */}
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PFC2ZSN" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            }}
          />
          {/* End Google Tag Manager (noscript) */}
        </>
      </Head>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PFC2ZSN" height="0" width="0" style="display:none;visibility:hidden;"></iframe>`,
        }}
      />

      <Flex
        backgroundRepeat="no-repeat"
        backgroundSize={{base: "cover", lg: "contain"}}
        color="white"
        flex={1}
        flexDirection="column"
        padding={{base: 6, lg: 12}}
        height='inherit'
      >
        <Link display='inline' href="https://web.pency.app/" width='148px'>
          <PencyLogo 
            display='inline' 
            marginBottom='1rem' 
            position='relative' 
            bottom='0.35rem' 
            right='0.25rem' 
          />
        </Link>
        <Stack maxWidth={{base: "100%", lg: 480}} spacing={{base: 1, lg: 3}}>
          <Heading
            fontSize={{base: "1.25rem", lg: "3.3rem"}}
            maxWidth='24rem'
            fontWeight={{base: 700}}
            letterSpacing={-0.5}
            marginTop={{base: 4, lg: '1.75rem'}}
          >
            {t("hero.title")}
          </Heading>
          <Text 
            color="#162D27" 
            fontSize={{base: "md", lg: "xl"}} 
            letterSpacing={0.5}>
            <Trans i18nKey="hero.subTitle_html" />
          </Text>
        </Stack>
        <Stack
          display={{base: "none", lg: "inherit"}}
          marginTop={6}
          maxWidth={{base: "100%", lg: 480}}
          spacing={{base: 3}}
        >
          <Stack isInline alignItems="flex-start" spacing={3}>
            <CheckIcon 
              size={14} 
              strokeWidth={3} 
              p={'3px'} 
              border='2px solid #22594B' 
              borderRadius='50%' 
              color="#22594B" 
            />
            <Text fontFamily='Recoleta' fontSize={{base: "md"}} lineHeight="24px">
              <Trans i18nKey="hero.bullets.one" />
            </Text>
          </Stack>

          <Stack isInline alignItems="flex-start" spacing={3}>
            <CheckIcon 
              strokeWidth={3}  
              size={14} 
              p={'3px'} 
              border='2px solid #22594B' 
              borderRadius='50%' 
              color="#22594B" 
            />
            <Text fontSize={{base: "md"}} lineHeight="24px">
              <Trans i18nKey="hero.bullets.two" />
            </Text>
          </Stack>

          <Stack isInline alignItems="flex-start" spacing={3}>
            <CheckIcon 
              size={14}    
              strokeWidth={3}  
              p={'3px'} 
              border='2px solid #22594B' 
              borderRadius='50%' 
              color="#22594B" 
            />
            <Text fontSize={{base: "md"}} lineHeight="24px">
              <Trans i18nKey="hero.bullets.three" />
            </Text>
          </Stack>
        </Stack>
        <Image 
          transform={{base: 'scale(0.85)', lg:'scale(1.25)'}}
          marginTop={{base:'0rem', lg: "3.5rem"}} 
          display={{base: 'none', lg: "block"}}
          position='relative' 
          top={{base: '0rem', lg: "3rem"}}
          right='4rem' 
          src='./signup.image.jpg' 
        />
      </Flex>
      <Stack
        flex={{base: 1, lg: 0.4}}
        maxWidth={512}
        minWidth={{base: "100%", lg: '45%'}}
        paddingTop='2rem'
        paddingX={{ base: 6, sm: 12 }}
        spacing={6}
        marginRight='3rem'
        marginTop={{base: '0', lg: '2rem'}}
        backgroundColor='#fff'
        borderRadius={{base: '0', lg: '0.5rem'}}
        height='inherit'
      >
        {["init", "pending", "rejected"].includes(status) && (
          <Stack shouldWrapChildren spacing={4}>
            <Stack spacing={0}>
              <Text fontSize="3xl" fontWeight={500}>
                {t("form.title")}
              </Text>
            </Stack>
            <CreateForm onSubmit={handleSubmit} checkbox={checkbox} setCheckbox={setCheckbox}>
              {({form, submit, isDirty}) => (
                <Stack shouldWrapChildren spacing={6}>
                  {form}
                  {status === "rejected" && (
                    <Text
                      backgroundColor="red.100"
                      borderColor="red.400"
                      borderRadius="md"
                      borderWidth={1}
                      color="red.500"
                      padding={2}
                      textAlign="center"
                    >
                      {error}
                    </Text>
                  )}
                  <Button
                    isDisabled={!isDirty||!checkbox.termsAndConditions}
                    isLoading={status === "pending"}
                    size="lg"
                    type="submit"
                    variantColor="primary"
                    width="100%"
                    onClick={submit}
                  >
                    {t("form.action")}
                  </Button>
                </Stack>
              )}
            </CreateForm>
            <Text color="gray.600" textAlign="center" marginBottom='2rem'>
              {t("form.haveAccount")}{" "}
              <Link
                color="primary.500"
                fontWeight={500}
                href={`${process.env.SIGNIN_URL}/${locale}`}
              >
                {t("form.startSession")}
              </Link>
            </Text>
          </Stack>
        )}

        {status === "resolved" && (
          <Stack spacing={2}>
            <Text fontSize="2xl" fontWeight={600}>
              {t("congratulations.title")}
            </Text>
            <Text>{t("congratulations.copy")}</Text>

            <Link
              isExternal
              color="primary.500"
              href={`${process.env.ADMIN_URL}/${tenant.slug}`}
              style={{textDecoration: "none"}}
              onClick={() => appboy.logCustomEvent("ACCESS-ACCOUNT")}
            >
              <Button marginY={4} size="lg" variantColor="primary" width="100%">
                {t("congratulations.actionOne")}
              </Button>
            </Link>

            <Link
              isExternal
              color="primary.500"
              fontWeight="600"
              href={process.env.COMMUNITY_GROUP_URL}
              marginTop={2}
              textAlign="center"
            >
              {t("congratulations.actionTwo")}
            </Link>
          </Stack>
        )}
      </Stack>
    </Flex>
       <Flex 
        marginTop={{base: '2rem', md: '4.25rem'}} 
        justifyContent='space-evenly' 
        paddingLeft={{base: '1rem', sm:'3rem'}}
        alignItems={{base: 'center', md: 'normal'}}
        paddingRight={{base:'0rem', sm: '1.25rem'}}
        flexDirection={{base: 'column', md: 'row'}}
        >
        {cardsContent.map((card, index: number) => (
          <PlanCard 
            key={index} 
            title={card.title} 
            subtitle={card.subtitle} 
            footerText={card.footerText} 
            image={card.image} 
            since={card.since}
          />
        ))}
     </Flex>
     </Box>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  // Return stores so we can build a directory
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
};

export default SignupRoute;
