import React from "react";
import Select from "@pency/components/inputs/Select";
import { useTranslation } from "~/i18n/hooks";
import { SelectProps } from "@chakra-ui/core";

interface Props extends Omit<SelectProps, "value" | "onChange"> {
  options: any;
  onChange?: (value: any) => void;
}

const CustomSelectInput: React.FC<Props> = ({
  onChange,
  options,
  ...props
}) => {
  const t = useTranslation();
  const handleSelect = (e) => onChange(e.target.value);
  const optionsToIterate = options(t);
  return (
    <Select height='3rem' defaultValue="" onChange={handleSelect} {...props}>
      {optionsToIterate}
    </Select>
  );
};

export default CustomSelectInput;
