import unfetch from "isomorphic-unfetch";

export default function fetch(
  method: "GET" | "PATCH" | "POST" | "DELETE" | "PUT",
  path: string,
  body: Record<string, any> = null,
  headers: Record<string, any> = {},
  mode: RequestInit["mode"] = "cors",
) {
  return unfetch(path, {
    method: method,
    mode,
    headers: {
      "content-type": "application/json",
      ...headers,
    },
    body: ["PATCH", "POST", "PUT"].includes(method) && body ? JSON.stringify(body) : null,
  }).then((res) => {
    try {
      if (res.ok) {
        return res.json();
      } else {
        return res.json().then((error) => Promise.reject(error));
      }
    } catch (error) {
      return Promise.reject(res);
    }
  });
}
