import React from "react";
import { Box, BoxProps } from "@chakra-ui/core";

interface Props extends Omit<BoxProps, "size"> {
  size?: number;
}

const PencyLogo: React.FC<Props> = ({ size = 36, ...props }) => {
  return (
    <Box width='130px' {...props} >
      <svg
        height={36}
        //viewBox="0 0 114 24"
        width='100%'
        viewBox="0 0 924 210"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M222.543 34.876H281.803C292.577 34.876 302.679 36.9147 310.76 40.3148C319.514 44.3948 325.574 49.8349 330.288 57.3151C335.002 64.7952 337.696 73.6364 337.696 83.8366C337.696 94.0367 335.002 102.876 330.288 110.356C325.574 117.837 318.841 123.277 310.76 127.357C302.005 131.437 292.577 132.797 281.803 132.797H258.908V174.279H223.217V34.876H222.543ZM278.436 102.197C285.843 102.197 291.905 100.836 295.945 98.1158C299.986 95.3958 302.005 90.6367 302.005 83.8366C302.005 77.0364 299.986 72.9557 295.945 69.5556C291.905 66.8356 286.517 65.4765 278.436 65.4765H258.234V102.197H278.436V102.197Z"
          fill="white"
        />
        <path
          d="M356.988 34.876H452.613V64.7959H392.679V89.277H447.226V119.197H392.679V143.678H454.632V173.598H356.988V34.876V34.876Z"
          fill="white"
        />
        <path
          d="M481.445 34.876H519.829L563.6 106.956L566.967 123.957H568.988V34.876H604.679V173.598H566.293L522.522 101.516L519.155 84.5156H517.136V173.598H481.445V34.876Z"
          fill="white"
        />
        <path
          d="M659.679 167.478C648.905 161.358 640.149 153.197 633.415 142.317C626.681 131.436 623.988 119.196 623.988 104.916C623.988 90.6357 627.355 78.3955 633.415 67.5153C640.149 56.6351 648.905 48.4753 659.679 42.3551C670.454 36.235 682.574 33.5146 696.042 33.5146C707.49 33.5146 717.592 35.555 727.02 38.9551C735.774 43.0352 743.18 47.7956 749.241 54.5957C755.301 61.3958 758.67 68.8753 760.69 77.7155L757.995 81.7962L727.02 87.2367C724.999 80.4365 721.632 74.9954 716.245 70.9153C710.857 66.8353 704.123 64.7959 695.368 64.7959C684.594 64.7959 675.841 68.1966 669.78 75.6768C663.72 83.1569 660.352 92.6768 660.352 104.237C660.352 116.477 663.72 125.997 669.78 132.797C676.514 140.277 685.269 143.678 696.716 143.678C704.124 143.678 710.857 141.637 715.57 137.557C720.284 133.477 724.326 128.037 727.02 121.237L757.995 126.678L760.69 130.757C755.303 145.717 747.221 156.598 736.447 164.078C725.672 171.559 712.205 174.958 696.716 174.958C683.248 176.318 671.127 172.919 659.679 167.478Z"
          fill="white"
        />
        <path
          d="M803.396 127.356L760.297 35.5547H798.008L818.21 79.7555L820.231 98.1155H822.25L824.27 79.7555L844.473 35.5547H882.184L839.085 127.356V174.278H803.396V127.356Z"
          fill="white"
        />
        <path
          d="M175.596 108.781C171.099 111.316 165.737 112.813 159.971 112.813C150.227 112.813 141.578 109.588 136.331 103.078C131.084 109.588 122.493 112.813 112.692 112.813C102.602 112.813 93.78 109.876 88.5908 103.021C83.4016 109.876 74.5223 112.813 64.4899 112.813C58.4359 112.813 52.8431 111.2 48.2305 108.435V111.258V130.902V174.682C48.2305 174.797 48.3458 174.854 48.4034 174.854H76.0791L89.6863 194.268L101.333 174.854H175.365C175.481 174.854 175.538 174.739 175.538 174.682V130.902V108.781H175.596Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M186.941 86.8219C186.941 86.8219 152.408 34.8792 152.063 34.7069C151.776 34.5345 70.9315 34.5345 70.6442 34.7643C70.3569 34.9367 36.3413 87.0518 36.3413 87.0518C36.2264 87.2241 36.2264 87.454 36.2838 87.6838C39.559 98.4861 50.7634 106.473 64.1513 106.473C72.6247 106.473 80.2468 103.292 85.5141 98.1955L87.3488 79.8487H89.5391L91.667 99.0003C96.8823 103.615 104.147 106.473 112.187 106.473C120.213 106.473 127.427 103.611 132.651 98.9916L134.565 79.8501H136.756L138.898 99.1283C144.112 103.677 151.292 106.473 159.246 106.473C172.519 106.473 183.723 98.601 187.056 87.9136C187.171 87.5114 187.171 87.1667 186.941 86.8219Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};

export default PencyLogo;
