import React from "react";

export function useAppboy() {
  const appboyKey = "cd79324d-7699-4033-b0fb-5178687227e3";
  const [appboy, setAppboy] = React.useState(undefined);

  React.useEffect(() => {
    import("@braze/web-sdk").then((appboySdk) => {
      if (appboySdk !== undefined) {
        setAppboy(appboySdk);
      }
    });
  }, []);
  return {appboy, appboyKey};
}
