import React from "react";
import { useTranslation } from "~/i18n/hooks";
import FormControl from "@pency/components/form/FormControl";
import { Controller } from "react-hook-form";
import CustomSelectInput from "~/ui/inputs/CustomSelectInput";
import { Box } from "@chakra-ui/core";

const FormSelectInput = ({ errors, control, input }) => {
  const { name, options, isRequired = false } = input;
  const t = useTranslation();

  return (
    <Box marginBottom="1rem">
      <FormControl
        isRequired={isRequired}
        error={errors[name]?.message}
        isInvalid={Boolean(errors[name])}
        label={t(input.label)}
        name={name}
      >
        <Controller
          as={CustomSelectInput}
          control={control}
          defaultValue=""
          name={name}
          options={options}
        />
      </FormControl>
    </Box>
  );
};

export default FormSelectInput;
