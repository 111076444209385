import React, {ChangeEvent} from "react";
import Input from "@pency/components/inputs/Input";
import {InputProps} from "@chakra-ui/core";

interface Props extends Omit<InputProps, "value" | "onChange"> {
  value?: string;
  lower?: boolean;
  onChange?: (value: Props["value"]) => void;
}

const LowercaseInput: React.FC<Props> = ({onChange, lower = true, ...props}) => {
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (lower) {
      return onChange(event.target.value.toLowerCase());
    } else {
      return onChange(event.target.value);
    }
  }

  return <Input onChange={handleChange} {...props} />;
};

export default LowercaseInput;
