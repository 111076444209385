import React from "react";
import FormControl from "@pency/components/form/FormControl";
import { Controller } from "react-hook-form";
import { useTranslation } from "~/i18n/hooks";
import Password from "@pency/components/inputs/Password";

const FormPasswordInput = ({ errors, control, input }) => {
  const { name, isRequired = false, size = "lg" } = input;
  const t = useTranslation();
  return (
    <FormControl
      isRequired={isRequired}
      error={errors[name]?.message}
      isInvalid={Boolean(errors[name])}
      label={t(input.label)}
      name={name}
      help={t("form.password.help")}
    >
      <Controller
        as={Password}
        control={control}
        defaultValue=""
        name={name}
        placeholder={t(input.placeholder)}
        size={size}
      />
    </FormControl>
  );
};

export default FormPasswordInput;
