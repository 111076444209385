import React from "react";
import FormControl from "@pency/components/form/FormControl";
import { Controller } from "react-hook-form";
import { useTranslation } from "~/i18n/hooks";
import WhatsAppInput from "~/ui/inputs/WhatsAppInput";
import { Box } from "@chakra-ui/core";

const FormWappInput = ({ 
  errors, 
  control, 
  input, 
  isValid, 
  setIsValid, 
  setSelectedCountryISO, 
  setPersonalPhoneSelectedCountryISO,
}) => {
  const { name, placeholder = "", label = "", help = "" } = input;
  const t = useTranslation();

  const countryCodeHook = name === 'personalPhone' 
    ? setPersonalPhoneSelectedCountryISO 
    : setSelectedCountryISO
  

  return (
    <Box marginBottom="1rem">
      <FormControl
        isRequired
        help={t(help)}
        error={!isValid[name] ? t("form.whatsapp.error") : errors[name]?.message}
        isInvalid={!isValid[name] ? true : Boolean(errors[name])}
        label={t(label)}
        name={name}
      >
        <Controller
          as={WhatsAppInput}
          control={control}
          defaultValue=""
          name={name}
          placeholder={t(placeholder)}
          rules={{
            required: true,
            pattern: /^\+(?:[0-9] ?){6,14}[0-9]$/,
            minLength: 5,
          }}
          setSelectedCountryISO={countryCodeHook}
          isValid={isValid}
          hasError={errors[name]}
          setIsValid={setIsValid}
        />
      </FormControl>
    </Box>
  );
};

export default FormWappInput;
