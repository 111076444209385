import React from "react";
import FormPasswordInput from "./FormPasswordInput";
import FormSelectInput from "./FormSelectInput";
import FormTextInput from "./FormTextInput";
import FormWappInput from "./FormWappInput";
import { Stack } from "@chakra-ui/core";
import { useTranslation } from "~/i18n/hooks";
import FormControl from "@pency/components/form/FormControl";
import { Controller } from "react-hook-form";
import ReCAPTCHA from "~/verification/inputs/ReCAPTCHAInput";
import FormSlugInput from "./FormSlugInput";
import { CountryCode } from "libphonenumber-js";

interface Props {
  errors: any;
  control: any;
  isValid: boolean
  setIsValid?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCountryISO?: React.Dispatch<React.SetStateAction<CountryCode>>;
  setPersonalPhoneSelectedCountryISO?: React.Dispatch<React.SetStateAction<CountryCode>>;
  input: any;
}

const FormController:React.FC<Props> = ({
  errors,
  control,
  isValid,
  setIsValid,
  setSelectedCountryISO,
  setPersonalPhoneSelectedCountryISO,
  input,
}) => {
  const isProductionAndRecaptcha =
    process.env.ENV === "production" && input.type === "recaptcha";
  const t = useTranslation();

  return (
    <Stack spacing={4}>
      {input.type === "text" && (
        <FormTextInput errors={errors} control={control} input={input} />
      )}
      {input.type === "select" && (
        <FormSelectInput errors={errors} control={control} input={input} />
      )}
      {input.type === "whatsapp" && (
        <FormWappInput
          errors={errors}
          control={control}
          input={input}
          isValid={isValid}
          setIsValid={setIsValid}
          setSelectedCountryISO={setSelectedCountryISO}
          setPersonalPhoneSelectedCountryISO={setPersonalPhoneSelectedCountryISO}
        />
      )}
      {input.type === "password" && (
        <FormPasswordInput errors={errors} control={control} input={input} />
      )}
      {input.type === "slug" && (
        <FormSlugInput errors={errors} control={control} input={input} />
      )}
      {isProductionAndRecaptcha && (
        <FormControl
          error={t("form.recaptcha.error")}
          isInvalid={Boolean(errors.recaptcha)}
          label={t("form.validation")}
          name="recaptcha"
        >
          <Controller
            as={ReCAPTCHA}
            control={control}
            defaultValue=""
            name="recaptcha"
          />
        </FormControl>
      )}
    </Stack>
  );
};

export default FormController;
