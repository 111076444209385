import { NEW_CATEGORIES } from "@pency/constants/catalogs/categories";
import { COUNTRIES } from "@pency/constants/catalogs/i18n";
import { CountryCode } from "libphonenumber-js";
import * as yup from "yup";

export interface CreateTenantForm {
  defaultValues: CreateTenantValues;
  builder: BuilderField[];
  validationSchema: any;
}

export interface BuilderField {
  id: number;
  type: FieldTypes;
  name: string;
  isRequired?: boolean;
  label?: string;
  placeholder?: string;
  options?: (t: any) => Element[];
  defaultIsChecked?: boolean;
  help?: string;
  terms?: string;
  privacy?: string;
  lower?: boolean;
}

export interface CreateTenantValues {
  title: string;
  slug: string;
  category: string;
  phone: string;
  personalPhone: string;
  country: CountryCode | "";
  email: string;
  password: string;
  recaptcha: string;
}

export enum FieldTypes {
  text = "text",
  slug = "slug",
  select = "select",
  password = "password",
  checkbox = "checkbox",
  whatsapp = "whatsapp",
  recaptcha = "recaptcha",
}

export const tenatValidationSchema = (t) =>
  yup.object<any>({
    title: yup.string().required(t("form.required")),
    slug: yup
      .string()
      .matches(/^[a-z0-9_\-\.]+([a-z0-9_])$/, t("form.username.error.match"))
      .min(4, t("form.username.error.min"))
      .required(t("form.required")),
    category: yup.string().required(t("form.required")),
    phone: yup.string().required(t("form.required")),
    personalPhone: yup.string().required(t("form.required")),
    country: yup.string().required(t("form.required")),
    email: yup
      .string()
      .email(t("form.invalidEmail"))
      .required(t("form.required")),
    password: yup
      .string()
      .required(t("form.required"))
      .min(8, t("form.password.error")),
    // Just ask for recaptcha on production
    recaptcha:
      process.env.ENV === "production" &&
      yup.string().required(t("form.required")),
  });

const getCategoryOptions = (t) => {
  const translatedCategories = NEW_CATEGORIES.map((category) => {
    return {
      original: category,
      translated: t(`catalogs.newCategories.${category}`),
    };
  });
  const sortedCategories = translatedCategories.sort((a, b) => {
    return a.translated.localeCompare(b.translated);
  });

  return [
    { original: "", translated: t("form.selectOption") },
    ...sortedCategories,
  ];
};

const formatedCountries = {
  SELECT: "form.country.placeholder",
  ...COUNTRIES,
};

const getCountryValue = (code: string) => {
  if (code === "SELECT") return "";
  return code;
};

export const formSchema: CreateTenantForm = {
  defaultValues: {
    title: "",
    slug: "",
    category: "",
    phone: "",
    personalPhone: "",
    country: "",
    email: "",
    password: "",
    recaptcha: "",
  },
  builder: [
    {
      id: 1,
      type: FieldTypes.text,
      name: "title",
      isRequired: true,
      label: "form.tenantName.label",
      placeholder: "form.tenantName.placeholder",
      lower: false,
    },
    {
      id: 2,
      type: FieldTypes.slug,
      name: "slug",
      isRequired: true,
      label: "form.username.label",
      placeholder: "form.username.placeholder",
    },
    {
      id: 3,
      type: FieldTypes.select,
      name: "category",
      isRequired: true,
      label: "form.category.label",
      placeholder: "form.category.placeholder",
      options: (t) =>
        getCategoryOptions(t).map<any>((category) => (
          <option key={category.original} value={category.original}>
            {category.translated}
          </option>
        )),
    },
    {
      id: 4,
      type: FieldTypes.whatsapp,
      name: "phone",
      isRequired: true,
      label: "form.whatsapp.label",
      placeholder: "form.whatsapp.placeholder",
    },
    {
      id: 9,
      type: FieldTypes.whatsapp,
      name: "personalPhone",
      isRequired: true,
      label: "form.personalPhone.label",
      placeholder: "form.whatsapp.placeholder",
      help: "form.personalPhone.help"
    },
    {
      id: 5,
      type: FieldTypes.select,
      name: "country",
      isRequired: true,
      label: "form.country.label",
      placeholder: "form.country.placeholder",
      options: (t) =>
        Object.entries(formatedCountries).map<any>(([code, name]) => (
          <option key={code} value={getCountryValue(code)}>
            {t(name)}
          </option>
        )),
    },
    {
      id: 6,
      type: FieldTypes.text,
      name: "email",
      isRequired: true,
      label: "form.email.label",
      placeholder: "form.email.placeholder",
    },
    {
      id: 7,
      type: FieldTypes.password,
      name: "password",
      isRequired: true,
      label: "form.password.label",
      placeholder: "form.password.placeholder",
    },
    {
      id: 8,
      type: FieldTypes.recaptcha,
      name: "recaptcha",
    },
  ],
  validationSchema: tenatValidationSchema,
};
