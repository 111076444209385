import React from "react";
import {
  Text,
  Flex,
  Box,
  Checkbox as ChakraCheckbox,
  CheckboxProps,
  Link,
} from "@chakra-ui/core";
import styled from "@emotion/styled";
import { useTranslation } from "~/i18n/hooks";

interface Props extends CheckboxProps {
  id?: string;
  text?: string;
  name: string;
  input?: any;
  handleChange?: any;
  defaultIsChecked: boolean;
  bold?: string;
  checkbox: any;
}

const CustomCheckoxInput: React.FC<Props> = ({
  id,
  text,
  name,
  input,
  handleChange,
  defaultIsChecked,
  checkbox,
  bold = "600",
  ...props
}) => {
  const t = useTranslation();
  const StyledCheckbox = styled(ChakraCheckbox)`
    input[type="checkbox"]:checked + [aria-hidden="true"] {
      background-color: #22594b;
      border-color: #22594b;
      box-shadow: none;
    }
    input[type="checkbox"]:focus + [aria-hidden="true"] {
      box-shadow: none;
    }
    div:last-of-type {
      font-size: ${id === "payments" ? "1rem" : "0.8rem"};
    }
  `;

  return (
    <Flex alignItems="center" marginTop="1.5rem">
      <StyledCheckbox
        fontWeight={bold}
        onChange={handleChange}
        isChecked={checkbox[name]}
        marginRight="0.5rem"
        {...props}
      />
      <Box>
        <Text as="span">{t(input.label)}</Text>
        {input.terms && (
          <Link
            // Se tiene que cambiar la url - PROD_CHANGE
            href="https://landing-git-feature-merge-newlanding-pencyapp.vercel.app/terminos-y-condiciones"
            cursor="pointer"
            color="#22594B"
          >
            {t(input.terms)}
          </Link>
        )}
        {input.terms && (
          <Text as='span'> {t('common.and')} </Text>
        )}
        {input.privacy && (
          <Link
            // Se tiene que cambiar la url - PROD_CHANGE
            href="https://landing-git-feature-merge-newlanding-pencyapp.vercel.app/politica-de-privacidad"
            cursor="pointer"
            color="#22594B"
          >
            {t(input.privacy)}
          </Link>
        )}
      </Box>
    </Flex>
  );
};

export default CustomCheckoxInput;
