import sc from "states-cities-db";
import {COUNTRIES as PencyCountries} from "@pency/constants/catalogs/i18n";

const COUNTRIES = sc.getCountries();

const filteredCountries = () => {
  const countries = COUNTRIES.filter((country) => PencyCountries[country.iso2]);
  return countries;
};

const getCountryTelCode = (country) => country && COUNTRIES.find(({iso}) => iso === country).prefix;

export {COUNTRIES, getCountryTelCode, filteredCountries};
