import React from "react";
import { FormControl } from "@chakra-ui/core";
import { BoxProps } from "@chakra-ui/core";
import { filteredCountries } from "../../utils/Countries";
import styled from '@emotion/styled'
import PhoneNumberInput from "./PhoneNumberInput";

const StyledFormControl = styled(FormControl)`
  .css-f5wfbu{
    height: 3rem
  }
`

interface Props extends BoxProps {
  value?: string;
  placeholder: string;
  setSelectedCountryISO: (value: Props["value"]) => void;
  onChange?: (value: any) => void;
  isValid: any;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  hasError: string;
  name: string;
}

const WhatsAppInput: React.FC<Props> = ({
  value,
  placeholder,
  onChange,
  setSelectedCountryISO,
  isValid,
  setIsValid,
  name,
  hasError,
  ...props
}) => {
  const countryOptions = filteredCountries().map(({ name, iso, iso2 }) => ({
    label: name,
    value: { iso, iso2 },
  }));

  function handleChange(data) {
    if (!isValid[name]) {
      setIsValid({
        ...isValid,
        [name]: true
      })
    }
    onChange && onChange(data);
  }

  return (
    <StyledFormControl>
      <PhoneNumberInput
        options={countryOptions}
        placeholder={placeholder}
        setSelectedCountryISO={setSelectedCountryISO}
        value={value}
        onChange={handleChange}
        height="3rem"
        isValid={isValid}
        name={name}
        hasError={hasError}
        {...props}
      />
    </StyledFormControl>
  );
};

export default WhatsAppInput;
