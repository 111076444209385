import React from "react";
import FormControl from "@pency/components/form/FormControl";
import { Controller } from "react-hook-form";
import { useTranslation } from "~/i18n/hooks";
import SlugInput from "~/ui/inputs/SlugInput";
import { Box } from "@chakra-ui/core";

const FormSlugInput = ({ errors, control, input }) => {
  const { name, isRequired = false, size = "lg" } = input;
  const t = useTranslation();

  return (
    <Box marginBottom="1rem">
      <FormControl
        isRequired={isRequired}
        error={
          errors[name] && (errors[name].message || t(errors[name].message))
        }
        isInvalid={Boolean(errors[name])}
        label={t(input.label)}
        name={name}
      >
        <Controller
          as={SlugInput}
          control={control}
          defaultValue=""
          name={name}
          size={size}
        />
      </FormControl>
    </Box>
  );
};

export default FormSlugInput;
