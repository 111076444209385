import React, {ChangeEvent} from "react";
import Input from "@pency/components/inputs/Input";
import {InputGroup, InputLeftAddon, InputProps} from "@chakra-ui/core";
import slugify from "slugify";

interface Props extends Omit<InputProps, "value" | "onChange"> {
  value?: string;
  onChange?: (value: Props["value"]) => void;
}

const SlugInput: React.FC<Props> = ({onChange, ...props}) => {
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    return onChange(
      slugify(event.target.value, {
        lower: true,
        remove: /[*+~,`=(){}\[\]\^\'\"!:@#\/\\]/g,
      }),
    );
  }

  return (
    <InputGroup>
      <InputLeftAddon height='3rem' children="https://pency.app/" backgroundColor="gray.300" />
      <Input height='3rem' onChange={handleChange} {...props} />
    </InputGroup>
  );
};

export default SlugInput;