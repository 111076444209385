import React, {useState} from "react";
import {Box, Flex, Select, InputGroup, InputLeftElement, InputRightElement} from "@chakra-ui/core";
import Flag from "react-world-flags";
import Input from "@pency/components/inputs/Input";
import Button from "@pency/components/controls/Button";
import {CountryCode, parsePhoneNumber, isValidPhoneNumber} from "libphonenumber-js";

import {getCountryTelCode} from "../../utils/Countries";

import {useTranslation} from "~/i18n/hooks";

interface Props {
  value?: string;
  options: any;
  placeholder: string;
  onChange?: (value: Props["value"]) => void;
  setSelectedCountryISO: (value: Props["value"]) => void;
  isValid: boolean;
  hasError: string;
  name: string;
}

const PhoneNumberInput: React.FC<Props> = ({
  value,
  onChange,
  options,
  placeholder,
  setSelectedCountryISO,
  isValid,
  hasError,
  name,
  ...props
}) => {
  const [number, setNumber] = useState<string>(value || "");
  const [selectedCountry, setSelectedCountry] = useState<string>("ARG");
  const [countryCode, setCountryCode] = useState<string>(getCountryTelCode(selectedCountry));
  const [selectedISO2, setSelectedISO2] = useState<CountryCode>("AR");

  const t = useTranslation();

  const onCountryChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    let value = event.target.value;
    for (const country of options) {
      if (country.value.iso === value) {
        setSelectedCountryISO(country.value.iso2);
        setSelectedISO2(country.value.iso2);
      }
    }
    let code = getCountryTelCode(value);
    setCountryCode(code);
    setSelectedCountry(value);
  };

  const onPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let eValue = event.target.value;
    setNumber(eValue);
    onChange(eValue);
  };

  function onClickCheckWhatsapp() {
    let testPhoneNumber = "";
    const isValidNumber = isValidPhoneNumber(number, selectedISO2);

    if (isValidNumber) {
      let tel = parsePhoneNumber(number, selectedISO2);
      let numberParsed = tel.format("E.164");
      testPhoneNumber = numberParsed;
    } else {
      testPhoneNumber = number;
    }

    window.open(`https://wa.me/${testPhoneNumber}`, "_blank");
  }

  const displayCheckBtn = value && name !== "personalPhone"

  return (
    <InputGroup>
      <InputLeftElement w={["35%", "30%", "30%"]}>
        <Select
          bottom={0}
          height="100%"
          left="0"
          opacity={0}
          position="absolute"
          top="0"
          value={selectedCountry}
          zIndex={1}
          onChange={onCountryChange}
          width={["7.7rem", "9.4rem"]}
        >
          {options.map((country) => (
            <option key={country.id} value={country.value.iso}>
              {country.label}
            </option>
          ))}
        </Select>
        <Flex alignItems="center" justifyContent="left" maxHeight="10px" pl={3} w="100%">
          {selectedCountry && (
            <>
              <Box flex={1} maxW="30%" pr={2}>
                <Flag code={selectedCountry || ""} height="1rem" width="100%" />
              </Box>
              <Box flex={1} w="90%">
                <p>{countryCode}</p>
              </Box>
            </>
          )}
        </Flex>
      </InputLeftElement>
      <Input
        autoComplete="off"
        pattern="[0-9]"
        pl={["6.7rem", "8.4rem"]}
        placeholder={placeholder}
        size="lg"
        type="text"
        value={number}
        onChange={onPhoneNumberChange}
        aria-invalid={!isValid[name] ? true : Boolean(hasError)}
        {...props}
      />
      <InputRightElement marginRight="0.5rem" width="94px">
        {displayCheckBtn && (
          <Button fontSize="sm" size="xs" variantColor="primary" onClick={onClickCheckWhatsapp}>
            {t("form.whatsapp.action")}
          </Button>
        )}
      </InputRightElement>
    </InputGroup>
  );
};

export default PhoneNumberInput;
