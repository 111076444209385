import {CreateTenantValuesInterface} from '@pency/api/tenant/controllers/post'
import fetch from "~/utils/fetch";

export default {
  create: (
    formData: CreateTenantValuesInterface,
    lang: string,
  ) =>
    fetch("POST", "/api/tenant", {
      formData,
      lang,
    }),
};
