import React from "react";
import FormControl from "@pency/components/form/FormControl";
import { Controller } from "react-hook-form";
import { Box } from "@chakra-ui/core";
import { useTranslation } from "~/i18n/hooks";
import LowercaseInput from "~/ui/inputs/LowercaseInput";

interface Props {
  errors: any;
  control: any;
  input: any;
}

const FormTextInput: React.FC<Props> = ({ errors, control, input }) => {
  const { name, isRequired = false, size = "lg" } = input;
  const t = useTranslation();

  return (
    <Box marginBottom='1rem'>
      <FormControl
        isRequired={isRequired}
        error={errors[name]?.message}
        isInvalid={Boolean(errors[name])}
        label={t(input.label)}
        name={name}
      >
        <Controller
          as={LowercaseInput}
          control={control}
          defaultValue=""
          lower={input.lower}
          name={name}
          placeholder={t(input.placeholder)}
          size={size}
        />
      </FormControl>
    </Box>
  );
};

export default FormTextInput;