import React from "react";
import { Box, Image, Text } from "@chakra-ui/core";
import Heading from "../ui/Heading";
import { textStyles } from "@pency/components/text/TextStyles";
import styled from "@emotion/styled";
import { useTranslation } from "~/i18n/hooks";

interface Props {
  image: string;
  title: string;
  subtitle: string;
  footerText: string;
  since?: string;
}

const FooterText = styled(Text)`
  font-family: "Recoleta-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", sans-serif !important;
`;

const PlanCard: React.FC<Props> = ({
  image,
  title,
  subtitle,
  footerText,
  since,
}) => {
  const t = useTranslation()
  return (
    <Box
      marginRight={{base: '1rem',sm:"2rem"}}
      background="#FFFFFF"
      borderRadius="1.25rem"
      maxWidth={{base: "100%", sm: '55%', md: "415px"}}
      paddingLeft={{base: "2rem", md: "2.8rem"}}
      paddingRight={{base: "1rem", md: "2rem"}}
      paddingBottom="1.75rem"
      marginBottom={{ base: "1rem", md: "0" }}
    >
      <Image src={image} />
      <Heading
        as="h2"
        marginBottom="0.875rem"
        fontWeight={700}
        fontSize="1.5rem"
        width="90%"
        lineHeight='1.5rem'
        marginTop='0.65rem'
        fontFamily="Recoleta"
      >
        {t(title)}
      </Heading>
      <Text
        {...textStyles.regular12}
        marginTop="0.875rem"
        color="#505458"
        marginBottom="0.5rem"
      >
        {t(subtitle)}
      </Text>
      <FooterText fontSize="1.5rem" fontWeight={700}>
        <Text
          as="span"
          fontSize="1.1rem"
          fontFamily='Visuelt Pro'
          fontWeight={700}
          textDecoration="uppercase"
        >
          {t(since) ?? ""}
        </Text>{" "}
        {t(footerText)}
      </FooterText>
    </Box>
  );
};

export default PlanCard;
